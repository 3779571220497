import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { Layout } from 'layout';

const NotFoundPage = () => {
  const { pageNotFound } = useStaticQuery(graphql`
    query {
      pageNotFound {
        text
      }
    }
  `);

  return (
    <Layout>
      <div className="not-found container">
        <div className="not-found__content">
          <p className="not-found__paragraph">{pageNotFound !== null ? pageNotFound.text : ''}</p>
        </div>
      </div>
    </Layout>
  );
};

export default NotFoundPage;
